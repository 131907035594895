@font-face {font-family: "Suisse Intl";
    src: url("./fonts/88f10bf18a36407ef36bf30bc25a3618.eot"); /* IE9*/
    src: url("./fonts/88f10bf18a36407ef36bf30bc25a3618.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("./fonts/88f10bf18a36407ef36bf30bc25a3618.woff2") format("woff2"), /* chrome firefox */
    url("./fonts/88f10bf18a36407ef36bf30bc25a3618.woff") format("woff"), /* chrome firefox */
    url("./fonts/88f10bf18a36407ef36bf30bc25a3618.ttf") format("truetype"), /* chrome firefox opera Safari, Android, iOS 4.2+*/
}

body {
    font-family: "Suisse Intl", sans-serif;
}

.single-image:hover a img {
    transform: none;
}

.header-right-wrap .same-style.header-search .search-content form .button-search,
.scroll-top {
    background: #000000;
    border-color: #000000;
    color: #ffffff;
}

a:hover {
    color: #000000;
}

.btn-hover a::after, .btn-hover button::after {
    background: #000000;
}

.login-register-wrapper .login-form-container .login-register-form form .button-box button:hover {
    background-color: #000000;
    color: #fff;
}

.product-wrap .product-img .product-action > div, .product-list-image-wrap .product-img .product-action > div {
    background-color: #3a434a;
}

.shop-top-bar .shop-tab a.active, .shop-top-bar .shop-tab button.active {
    color: #000;
}

.product-details-content .pro-details-quality .pro-details-cart button.bg-none:hover {
    color: #fff;
	background-color: #000000;
}

.related-product-area .swiper-container-horizontal {
    width: 100%;
}

.hover-bottom-border:hover {
    background: #000000;
}

.box-head-title {
    padding: 30% 0;
}

.hover-bottom-border:hover button.btn, .hover-bottom-border:hover h4 {
    color: #fff;
}

.home-banner-img {
    background-position: top !important;
    background-size: 100% !important;
}

.label-rounded {
    border: 1px solid #dee2e6;
    margin: 0 5px;
    padding: 5px 15px;
    border-radius: 15px;
}

.cat-head-list {
	z-index: 100;
	width: 100%;
	margin-top: 7%;
	background: #f4f3f1;
	padding: 10px;
	text-align: center;
	margin-bottom: 15px;
}
.slider-home-btn {
    border: 0;
    padding: 0 25px;
    border-radius: 50%;
    background: #000;
    color: #fff;
    font-size: 30px;
	top:25%;
}

@media only screen and (max-width: 767px) {
	.cat-head-list {
		overflow: hidden;
		overflow-x: scroll;
	}
	.col-xs-6 {
		width: 50%;
	}
	.home-slider-text h1.animated {
		font-size: 20px;
	}
	.home-slider-text p {
		font-size: 15px;
		margin-bottom: 20px;
	}
	.home-page-slider-img .pro-img {
		min-height: 150px;
	}
}

.offcanvas-mobile-search-area a {
    background: none;
    border: none;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    color: #aaa;
    padding: 0;
	font-size: 20px;
}

.header-right-wrap.header-right-wrap-white .same-style.header-wishlist > a span.count-style,
.header-right-wrap.header-right-wrap-white .same-style.account-setting > button span.count-style{
	background-color: #000;
    color: #fff;
}

.header-right-wrap.header-right-wrap-white .same-style.header-wishlist > a,
.header-right-wrap.header-right-wrap-white .same-style.account-setting > button{
	color: #000
}

.cat-head-list a.set-home-dropdown-button {
    padding: 0 30px;
    font-size: 17px;
    font-weight: 500;
}

.header-right-wrap .same-style.header-search .search-content {
    position: absolute;
    top: -5px;
	box-shadow: none;
}

.header-right-wrap .same-style.header-search .search-content form input {
    height: unset;
}

.home-banner-img .slider-content-5 .animated,
.home-banner-img .slider-content-5 p {
    color: #3a434a !important;
}

.home-banner-img .slider-content-5 a.animated {
    color: #fff !important;
    background: #3a434a;
}

.register-error-msg{
	position:absolute;
	bottom:5px;
	display:block;
}
.product-details-content .pro-details-size-color.sell-product .pro-details-size .pro-details-size-content--single {
    width: 25%;
    margin: 3px;
    border: 1px solid #f3f3f3;
    text-align: center;
	background: none;
}
.product-details-content .pro-details-size-color.sell-product .pro-details-size .pro-details-size-content--single.bg-gray {
    background: #f7f7f7;
}
.product-details-content .pro-details-size-color.sell-product .pro-details-size .pro-details-size-content--single.bg-gray:hover {
    background: #000000;
}
.product-details-content .pro-details-size-color .pro-details-size .pro-details-size-content--single:hover .size-name .text-primary {
    color: #fff !important;
}
.product-details-content .pro-details-size-color .pro-details-size .pro-details-size-content--single input:checked ~ .size-name .text-primary {
    color: #fff !important;
}
.product-details-content .pro-details-size-color .pro-details-size .pro-details-size-content--single input:checked ~ .size-name {
    background-color: #000000;
    color: #ffffff;
}
.product-details-content .pro-details-size-color .pro-details-size .pro-details-size-content--single:hover {
    background-color: #000000;
    color: #fff;
}

.product-details-content .pro-details-size-color.radio-color .pro-details-size .pro-details-size-content--single input:checked ~ .size-name{
	    background-color: #c1c1c1;
		color: #ffffff;
}

.product-details-content .pro-details-size-color .pro-details-size .pro-details-size-content--single .size-name {
    margin-bottom: 0;
    width: 100%;
    padding: 8px;
    font-size: 14px;
    font-weight: bold;
}

.product-details-content .pro-details-size-color .pro-details-size .pro-details-size-content--single .size-name .text-primary {
    font-weight: 500;
    font-family: 'Poppins';
}

.table-design .table-responsive-md {
    background: #fff;
    padding: 25px;
}

.product-details-content .pro-details-quality .pro-details-cart a, .product-details-content .pro-details-quality .pro-details-cart button{
	z-index:1;
}

.tabs-left {
  border-bottom: none;
  border-right: 1px solid #ddd;
}

.tabs-left>li {
  float: none;
 margin:0px;
  
}

.slider-content-5 h1 {
    font-size: 50px;
}

.product-img-badges span.purple {
    display: initial;
    margin: 3px;
}

.myproduct-bgcolor {
	display: none;
}
.myproduct-bgcolor.active {
    display: flex;
}

.not-allowed {
     cursor: not-allowed !important;
}

span.gallery-close {
    border-radius: 10px;
    position: absolute;
    right: -7px;
    margin-top: -7px;
    background: #ff00008a;
    color: #fff;
    padding: 0 5px;
	cursor: pointer;
}

.sell-product .pro-details-size-content--single input.sizes-status:disabled + .size-name {
    background: #efefef !important;
}

.buyseller-field.switch-field label {
    width: 49%;
    padding: 15px;
    font-size: 17px;
    font-weight: bold;
}

.text-gray {
    color: gray;
}
.nav-tabs .nav-link.active {
    color: #000000;
    background-color: #dcdcdc;
    border-color: #dee2e6 #dee2e6 #fff;
}

.header-right-wrap .same-style .notify-sec {
    max-height: 250px;
    width: 300px;
	overflow: hidden;
    overflow-y: scroll;
}
.notify-text-description {
    font-size: 12px;
    line-height: 17px;
}

.step-sec {
    display: none;
}

.step-sec.active {
    display: flex;
}

.login-register-wrapper .login-form-container .login-register-form form select {
    background-color: transparent;
    border: 1px solid #ebebeb;
    color: #333;
    font-size: 14px;
    height: 45px;
    margin-bottom: 30px;
    padding: 0 15px;
}

.select__control.css-1s2u09g-control {
    border-radius: 0;
    border-color: hsl(0deg 0% 92%);
}

.login-register-wrapper .login-form-container .login-register-form form .css-7pwich-Input input {
    height: 40px;
}

.cursor-pointer {
    cursor: pointer;
}

.ck.ck-toolbar {
    border: 1px solid #dee2e6 !important;
}
.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
    border-color: #dee2e6 !important;
}
.select__input-container.css-7pwich-Input {
    margin: 0;
    padding-bottom: 0;
    padding-top: 0;
}

.manually-product input.sizes-status {
    display: none;
}

[dir=ltr] .ck.ck-dropdown .ck-dropdown__arrow{
	z-index:0;
}

.dataTables_filter label, .dataTables_length label {
    display: flex;
}

.dataTables_length label select, .dataTables_filter label input[type="search"] {
    height: 30px !important;
    margin: 0 10px !important;
}

button.btn-coupon-apply {
    padding: 12px !important;
}

.product-large-image-wrapper.used-image .react_lightgallery_item {
    top: -3px;
    right: 15px;
}
.product-large-image-wrapper.used-image .react_lightgallery_item i.pe-7s-expand1 {
    font-size: 20px;
}
button.filter-padding {
    padding: 12px 25px !important;
    margin-top: 8px;
}

.my-sell-accordion h3.panel-title {
    padding: 0 10px;
}

.my-sell-accordion .single-my-account h3.panel-title::before {
    top: 0;
}

.addproduct-error-msg {
    position: absolute;
    display: block;
    bottom: 7px;
}

.fullscreen {
    z-index: 1000000;
}
.fullscreen .modal-content {
    height: 100%;
}
.fullscreen .modal-dialog {
    width: 100%;
    max-width: none;
    height: 100%;
    margin: 0;
    padding: 0;
}

.fullscreen-right {
    z-index: 1000000;
}
.fullscreen-right .modal-content {
    height: 100%;
}
.fullscreen-right .modal-dialog {
    width: 35%;
    max-width: none;
    height: 100%;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 0;
}
.fullscreen-right .modal-body {
    overflow: hidden;
    overflow-y: scroll;
}

.product-details-content .pro-details-quality .pro-details-cart button.bg-none {
    background: none;
    color: #000;
    border: 1px solid black;
}

.set-home-dropdown, .set-home-dropdown:focus, .set-home-dropdown:hover {
    background: transparent !important;
    border: 0 !important;
    box-shadow: none !important;
	color: #555252 !important;
}

.main-menu.menu-white nav ul li > a {
    color: #555252;
}

.stick .main-menu nav ul li a.set-home-dropdown-button {
    line-height: 30px !important;
}

.breadcrumb-area {
    padding: 15px;
}

.breadcrumb-area .breadcrumb-content {
    text-align: left !important;
}

.stick .main-menu.menu-white nav ul li button {
    color: #555252 !important;
}

.btn-follow {
    position: absolute;
    right: 5%;
    cursor: pointer;
	margin-top: -25px;
}

span.btn-top-model {
    margin-right: 15px;
}

.model-width-25 .modal-dialog {
    width: 25%;
}

.modal.show .modal-dialog li {
    width: 50%;
    display: inline-block;
    text-align: center;
    font-size: 35px;
    padding: 10px;
}

.notify-border {
    border-bottom: 2px dotted black;
    flex: 1 1 0%;
    margin-left: 10px;
    margin-right: 10px;
}

.notify-sec{
	display: flex;
	flex-direction: row;
	-webkit-box-align: baseline;
	align-items: baseline;
	-webkit-box-pack: justify;
	justify-content: space-between;
}

.notify-page input[type="radio"] {
    display: none;
}

.notify-page .btn-group label {
    font-size: 13px;
}

/* start my account page side bar */

.my-account-side {
    padding: 5px 0;
    color: #000;
}

.my-account-side p {
    line-height: 18px;
}

.my-account-side:hover {
    background: #dcdcdc;
	cursor:pointer;
}
.my-account-side.active {
    background: #dcdcdc;
}

/* end my account page side bar */

/* start popup */

.popover-title {
  line-height: 0px;
  padding: 15px 0;
}

.popover-wrapper {
  position: relative;
}
.popover-content {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: -130px;
  transform: translate(0, 10px);
  background-color: #000000;
  padding: 0 5px;
  color: #fff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  width: auto;
}
.popover-content:before {
  position: absolute;
  z-index: -1;
  content: "";
  right: calc(0% - 10px);
  border-style: solid;
  border-width: 10px 0px 10px 10px;
  border-color: transparent transparent #00000000 black;
  transition-duration: 0.3s;
  transition-property: transform;
}
.popover-wrapper:hover .popover-content {
  z-index: 10;
  opacity: 1;
  visibility: visible;
  transform: translate(0, -20px);
  transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
}
.popover-message {
  text-align: center;
}

/* end popup */

/* start switch radio */


.switch-field {
	overflow: hidden;
}

.switch-field input {
	position: absolute !important;
	clip: rect(0, 0, 0, 0);
	height: 1px;
	width: 1px;
	border: 0;
	overflow: hidden;
}

.switch-field label {
	background-color: #e4e4e4;
	color: rgba(0, 0, 0, 0.6);
	font-size: 14px;
	line-height: 1;
	text-align: center;
	padding: 8px 16px;
	margin-right: -1px;
	border: 1px solid rgba(0, 0, 0, 0.2);
	transition: all 0.1s ease-in-out;
}

.switch-field label:hover {
	cursor: pointer;
}

.switch-field input:checked + label {
	background-color: #333437;
    box-shadow: none;
    color: #fff;
}

/* end switch radio */


/* start tooltip */

.tooltip-cus {
	color: #858796; outline: none;
	cursor: help; text-decoration: none;
	position: relative;
}
.tooltip-cus span {
	position: absolute;
	display:none;
}
.tooltip-cus:hover span {
	border-radius: 5px 5px; 
	-moz-border-radius: 5px; 
	-webkit-border-radius: 5px; 
	box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1); 
	-webkit-box-shadow: 5px 5px rgba(0, 0, 0, 0.1); 
	-moz-box-shadow: 5px 5px rgba(0, 0, 0, 0.1);
	position: absolute; top: 2em; 
	z-index: 99;
	margin-left: 0; width: 250px;
	display:block;
}
.tooltip-cus:hover img {
	border: 0; margin: -10px 0 0 -55px;
	float: left; position: absolute;
}
.tooltip-cus:hover em {
	font-family: Candara, Tahoma, Geneva, sans-serif; font-size: 1.2em; font-weight: bold;
	display: block; padding: 0.2em 0 0.6em 0;
}
.classic { padding: 0.8em 1em; }
.custom { padding: 10px 15px; }
* html a:hover { background: transparent; }
.classic {background: #FFFFAA; border: 1px solid #FFAD33; }
.critical { background: #FFCCAA; border: 1px solid #FF3334;	}
.help { background: #fdfdfd; border: 1px solid #c1c1c1;	}
.info { background: #9FDAEE; border: 1px solid #2BB0D7;	}
.warning { background: #FFFFAA; border: 1px solid #FFAD33; }


/* end tooltip  */


/* start file upload */

.file-area {
  width: 100%;
  position: relative;
}
.file-area input[type=file] {
  position: absolute;
  width: 100%;
  height: 100% !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
}
.file-area .file-dummy {
  width: 100%;
  height: 130px;
  background: url(/assets/img/product/dummy.jpg) no-repeat;
    background-size: cover;
    background-position: center;
  border: 2px dashed rgb(204 200 200 / 99%);
  text-align: center;
  transition: background 0.3s ease-in-out;
}

.file-area:hover .file-dummy {
  background: rgba(255, 255, 255, 0.1);
}
.file-area input[type=file]:focus + .file-dummy {
  outline: 2px solid rgba(255, 255, 255, 0.5);
  outline: -webkit-focus-ring-color auto 5px;
}

.file-area input[type=file]:valid + .file-dummy .success {
  display: inline-block;
}
.file-area input[type=file]:valid + .file-dummy .default {
  display: none;
}

/* end file upload */ 

.btnbuysellShow {
    position: absolute;
    top: 0;
    right: 3%;
}

/* start loader */ 

.container-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #11111147;
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10000000;
    background-size: 10%;	
}

.loader-sec{
	display:none !important;
}

.container-loader .loader {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 200px;
}

.container-loader .loader span {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px solid #fff;
    pointer-events: none;
    animation: animateThreads 5s linear infinite;
}

.container-loader .loader span:nth-child(1) {
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70% ;
}

.container-loader .loader span:nth-child(2) {
    animation-direction: reverse;
    border-radius: 67% 33% 70% 30% / 75% 65% 35% 25%;
}

.container-loader .loader span:nth-child(3) {
    animation-duration: 3s;
    border-radius: 52% 48% 70% 30% / 41% 65% 35% 59%;
}

@keyframes animateThreads {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.container-loader .loader h2 {
    color: #fff;
    font-weight: 500;
}

/* end loader */ 



/* start Vertical Steps*/
.list-group.vertical-steps{
  padding-left:10px;
}
.list-group.vertical-steps .list-group-item{
  border:none;
  border-left:3px solid #ece5dd;
  box-sizing:border-box;
  border-radius:0;
  counter-increment: step-counter;
  display: unset !important;
  text-align: left !important;
  font-size: unset !important;
  padding-left:20px !important;
  padding-right:0px !important;
  padding-bottom:20px !important;  
  padding-top:0px !important;
}
.list-group.vertical-steps .list-group-item.active{
  background-color:transparent;
  color:inherit;
}
.list-group.vertical-steps .list-group-item:last-child{
  border-left:3px solid transparent;
  padding-bottom:0;
}
.list-group.vertical-steps .list-group-item::before {
  border-radius: 50%;
  background-color:#bc8459;
  color:#555;
  content: counter(step-counter);
  display:inline-block;
  float:left;
  height:25px;
  line-height:25px;
  margin-left:-35px;
  text-align:center;  
  width:25px;  
}
.list-group.vertical-steps .list-group-item span,
.list-group.vertical-steps .list-group-item a{
  display:block;
  overflow:hidden;
  padding-top:2px;
}

.list-group.vertical-steps .list-group-item.active::before{
  background-color:#bc8459;
  color:#fff;
}
.list-group.vertical-steps .list-group-item.completed{
  border-left:3px dashed #bc8459;
}
.list-group.vertical-steps .list-group-item.completed::before{
  background-color:#bc8459;
  color:#fff;
}
.list-group.vertical-steps .list-group-item.completed:last-child{
  border-left:3px dashed transparent;
}
.list-group.vertical-steps .list-group-item i {
    color: #bc8459;
}
.sell-understand-process {
    background: #f1f1f1;
    overflow: hidden;
    overflow-y: scroll;
}
.list-group-item.completed {
    background: transparent;
}
/* end Vertical Steps*/

/*  start range */

.range {
  -webkit-appearance: none;
  vertical-align: middle;
  outline: none;
  border: none;
  padding: 0;
  background: none;
}

.range::-webkit-slider-runnable-track {
  background-color: #d7dbdd;
  height: 6px;
  border-radius: 3px;
  border: 1px solid transparent;
}

.range[disabled]::-webkit-slider-runnable-track {
  border: 1px solid #d7dbdd;
  background-color: transparent;
  opacity: 0.4;
}

.range::-moz-range-track {
  background-color: #d7dbdd;
  height: 6px;
  border-radius: 3px;
  border: none;
}

.range::-ms-track {
  color: transparent;
  border: none;
  background: none;
  height: 6px;
}

.range::-ms-fill-lower { 
  background-color: #d7dbdd;
  border-radius: 3px;
}

.range::-ms-fill-upper { 
  background-color: #d7dbdd;
  border-radius: 3px;
}

.range::-ms-tooltip { display: none; /* display and visibility only */ }

.range::-moz-range-thumb {
  border-radius: 20px;
  height: 18px;
  width: 18px;
  border: none;
  background: none;
  background-color: #606670;
}

.range:active::-moz-range-thumb {
  outline: none;
}

.range::-webkit-slider-thumb {
  -webkit-appearance: none !important;
  border-radius: 100%;
  background-color: #606670;
  height: 18px;
  width: 18px;
  margin-top: -7px;
}

.range[disabled]::-webkit-slider-thumb {
  background-color: transparent;
  border: 1px solid #d7dbdd;
}

.range:active::-webkit-slider-thumb {
  outline: none;
}

.range::-ms-thumb { 
  border-radius: 100%;
  background-color: #606670;
  height: 18px;
  width: 18px; 
  border: none;
}

.range:active::-ms-thumb {
  border: none;
}

output {
  border: 1px solid #d7dbdd;
  color: #333;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  padding: .4em .6em;
  border-radius: 3px;
}


/* end range */

.add-buyer-size-page-dropdown .single-my-account h3.panel-title::before {
    top: 9px;
}

.add-buyer-size-page-dropdown .card-body {
    max-height: 300px;
    overflow: hidden;
    overflow-y: scroll;
}

.btn-black-14 {
    border: 0;
    background: black;
    color: #fff;
    font-size: 14px;
    padding: 0 20px;
}

.model-width-50 .modal-dialog {
    width: 50%;
}

.z-index-100{
	z-index:100;
}

.btn-black {
    border: 0;
    padding: 5px 40px;
    background: #343538;
    color: #fff;
    font-weight: bold;
}

/* start shipPDF */

.page-no {
    padding: 10px 15px;
    background: #40ac33;
    color: #fff;
    font-weight: bold;
    border-radius: 50%;
}

.ship-title {
    font-size: 55px;
    font-weight: bold;
    line-height: 60px;
}

.shipdate {
    color: #fff;
    background: #be1c2b;
    font-size: 24px;
    font-weight: bold;
}

.shiporder-no {
    font-size: 30px;
    font-weight: bold;
    margin-top: 5px;
}

.ship-guid {padding-left: 30px;}

.modal.show .modal-dialog .ship-guid li {
    width: 100%;
    font-size: 12px;
    text-align: left;
    display: list-item;
    font-weight: 600;
    padding: 5px 10px;
}

.modal.show .modal-dialog .ship-guid li::marker {
    color: #8b837a;
    font-size: 17px;
}

.vertical-align-bottom {
    padding-top: 8%;
}
.line-height-1 {
    line-height: 15px;
	text-transform: uppercase;
}

.checkbox-medium {
    width: 50%;
}

.size-home-small .modal-content {
    margin: auto;
    width: 40%;
}
.kep-login-facebook.metro {
    width: 100%;
    padding: 0.5rem;
    font-size: 14px;
    font-family: "Suisse Intl", sans-serif;
    font-weight: 200;
    background: transparent;
    border: 1px solid #dee2e6;
    color: #757575;
    text-transform: capitalize;
}

.kep-login-facebook.metro i {
    color: #3b5998;
}

/* end shipPDF */


.header-right-wrap .same-style .account-dropdown.add-width {
    width: 400px;
}

.border-solid-2px {
    border: 3px solid;
    padding: 10px;
    text-align: center;
}
.border-gold {
    border-color: #FF9D58;
    color: #FF9D58;
}

.list-style-type{
	list-style-type:disc;
}

.text-gold {
    color: #ff9d58;
}

.border-black {
    border: 1px solid black;
}

.sidebar-widget-list .size-dynamic-list {
    max-height: 200px;
    overflow: hidden;
    overflow-y: scroll;
}

.text-uppercase {
    text-transform: uppercase;
}

.bg-gray{
	background:#242424;
}

.account-setting:hover .account-dropdown, 
.account-setting .account-dropdown:hover {
	visibility: visible;
	opacity: 1;
	transform: rotateX(0deg);
}

.cut-sec {
    border: 1px dashed #c1c1c1;
}

i.fa.fa-scissors {
    position: absolute;
    margin-top: -24px;
}

.second-page-margin {
    margin-top: 50% !important;
}

.sell-product-notes ul {
    list-style-type: disc;
    font-size: 15px;
    padding-left: 17px;
}

.sell-product-notes ul li {
    margin-bottom: 10px;
}


.scroll-modal-body .modal-body {
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
}

.cursor-not-allwed {
    cursor: not-allowed;
}

.search-results-pf {
    background: #fff;
    padding: 15px 15px;
	margin-top: 5px;
}

.btn-outline-black {
    border: 1px solid #343538;
    padding: 5px 40px;
}

.font-width-label-radio label {
    font-size: 12px;
    width: 90px;
}

.apexcharts-toolbar {
    display: none !important;
}

.font-size-12 {
    font-size: 12px;
}

.search-results-pf .product-pf-result {
	margin-bottom: 10px;
	border-bottom: 1px solid #f0f0f0;
	padding-bottom: 10px;
}

/* start loading */

/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display:none;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgb(255 255 255), rgb(255 255 255));

  background: -webkit-radial-gradient(rgb(255 255 255), rgb(255 255 255));
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: '';
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 150ms infinite linear;
  -moz-animation: spinner 150ms infinite linear;
  -ms-animation: spinner 150ms infinite linear;
  -o-animation: spinner 150ms infinite linear;
  animation: spinner 150ms infinite linear;
  border-radius: 0.5em;
	-webkit-box-shadow: rgba(0,0,0, 0.75) 1.5em 0 0 0, rgba(0,0,0, 0.75) 1.1em 1.1em 0 0, rgba(0,0,0, 0.75) 0 1.5em 0 0, rgba(0,0,0, 0.75) -1.1em 1.1em 0 0, rgba(0,0,0, 0.75) -1.5em 0 0 0, rgba(0,0,0, 0.75) -1.1em -1.1em 0 0, rgba(0,0,0, 0.75) 0 -1.5em 0 0, rgba(0,0,0, 0.75) 1.1em -1.1em 0 0;
	box-shadow: rgba(0,0,0, 0.75) 1.5em 0 0 0, rgba(0,0,0, 0.75) 1.1em 1.1em 0 0, rgba(0,0,0, 0.75) 0 1.5em 0 0, rgba(0,0,0, 0.75) -1.1em 1.1em 0 0, rgba(0,0,0, 0.75) -1.5em 0 0 0, rgba(0,0,0, 0.75) -1.1em -1.1em 0 0, rgba(0,0,0, 0.75) 0 -1.5em 0 0, rgba(0,0,0, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* end loading */

@media only screen and (max-width: 767px) {
	.desktop-sec {
		display: none !important;
	}
	
	.mobile-sec {
		display: block !important;
	}
}

@media only screen and (min-width: 768px) {
	.desktop-sec {
		display: block !important;
	}
	
	.mobile-sec {
		display: none !important;
	}
}
.user-profile{
    margin-left: 20px !important;
}
.logo.logo-hm5{
    margin-top: 0px !important;
}
.logo{
    margin-top: 0px !important;
}
.footer-top{
    background-color: #ebe9e9  !important;
    color: #000 !important;
}
.footer-bottom{
    background-color: #ebe9e9 !important;
    color: #000 !important;
}
.footer-top .footer-social ul li a {
    color: #909090;
   
}
.footer-area h2{
    background-color: #ebe9e9 !important;
}
.account-dropdown .set-home-dropdown-button:hover{
    color: #000 !important;
}
.main-menu nav ul li > a:hover{
    color: #000 !important;
}
.header-right-wrap .same-style.account-setting > button:hover{
    color: #000 !important;
}

.header-right-wrap .same-style .account-dropdown:hover{
    color: #000 !important;
}
.footer-top .footer-social ul li a:hover{
    color: #000;
}
.single-mission{
    background: #f3f3f3;
    padding: 30px;
    border-radius: 20px;
    border: 1px solid #c1c1c1 !important;
}
.contact-form .contact-form-style button:hover{
    background-color: #f2f2f2;
    color: #000;
    border: 1px solid #c1c1c1;
}
.contact-info-wrap .single-contact-info .contact-info-dec p a:hover{
    color: #000;
}
.contact-social ul li a:hover{
    color: #000;
}
.login-register-wrapper .login-register-tab-list .nav-item a.active h4{
    color: #000000 !important;
}
.login-register-wrapper .login-register-tab-list .nav-item a h4{
    color: #c1c1c1 !important;
}
.subscribe-area-3 {
    margin-top: 60px;
}